.e-dlg-container {
    z-index: 3000 !important;
}

.e-dialog {
    .e-dlg-overlay {
        background-color: slategray !important;
        opacity: 0.6 !important;
    }

    .e-dlg-header-content {
        background: var(--secondary-color) !important; //rebranding changes
        padding: 10px 18px 10px 18px !important;
    }

    .e-dlg-header {
        text-align: left !important;
        font-family: "Montserrat-Bold";
        letter-spacing: 0px !important;
        color: var(--primary-color) !important; //rebranding changes
        opacity: 1 !important;
        font-size: 18px !important;
    }

    .e-dlg-content {
        padding-top: 15px !important;
        border-radius: 0 0 5px 5px;
        .text-center {
            h2 {
                font-family: "Montserrat-Bold";
            }
            p {
                font-family: "Open-Sans-Regular";
                font-size: 15px;
            }
        }

        textarea.e-input,
        .e-float-input textarea,
        .e-float-input.e-control-wrapper textarea,
        .e-input-group textarea,
        .e-input-group.e-control-wrapper textarea {
            resize: none !important;
        }

        .label {
            font-size: 14px !important;
            font-family: "Open-Sans-Regular";
            margin-bottom: 10px !important;
        }
    }

    .e-footer-content {
        padding: 0px 15px 15px 0px !important;
        border-radius: 0 0 5px 5px;
    }
}

.pointer {
    cursor: pointer;
}

.e-tab {
    .e-tab-header {
        .e-indicator {
            background: var(--primary-color) !important; //rebranding changes
            margin-left: 15px;
        }

        .e-toolbar-item {
            .e-tab-wrap {
                padding: 0px;
            }
            .e-tab-text {
                color: #5495bd !important;    //TODO need to change explicitly for rebranding
                text-transform: none;
                font-size: 15px;
                font-family: "Montserrat-Regular";
            }
        }

        .e-toolbar-item.e-active .e-tab-text {
            color: var(--primary-color) !important; //rebranding changes
            font-family: "Montserrat-Bold";
            padding-left: 7px;
        }
    }

    .e-content .e-item {
        padding-top: 20px !important; // TODO Custom css file should be reviewed.... Its css getting affected in the whole application
    }
}

// checkbox CSS Starts

.e-checkbox-wrapper .e-frame + .e-label,
.e-css.e-checkbox-wrapper .e-frame + .e-label {
    margin-left: 10px;
    font-family: "Open-Sans-SemiBold";
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
    border-color: var(--primary-color); //rebranding changes
    border-radius: 5px;
}

.e-checkbox-wrapper:hover .e-frame,
.e-css.e-checkbox-wrapper:hover .e-frame {
    border-color: var(--primary-color); //rebranding changes
    border-radius: 5px;
}

.e-checkbox-wrapper .e-checkbox:focus + .e-frame,
.e-css.e-checkbox-wrapper .e-checkbox:focus + .e-frame {
    border-color: var(--primary-color); //rebranding changes
    box-shadow: none;
}

//Checkbox CSS Ends

.rectangle {
    .e-outline {
        border-radius: 5px !important;
    }
}

// Dropdown auto width custom css
.custom-ddl {
    min-width: 214px !important;
    width: fit-content !important;
    max-width: 700px !important;

    .e-content .e-list-parent {
        li {
            text-indent: 0 !important;
            padding: 8px 16px !important;
            line-height: 20px !important;
            text-overflow: visible !important;
            white-space: pre-wrap !important;
        }
    }
}
// Switch toggle css for rebranding
.e-switch-wrapper,
.e-css.e-switch-wrapper {
    height: 17px;
    width: 28px;
}
.e-switch-wrapper .e-switch-handle {
    height: 8px !important;
    width: 8px !important;
}
.e-switch-wrapper .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
    background-color: var(--primary-color); //rebranding changes
    margin-left: -14px;
}
.e-switch-wrapper .e-switch-inner.e-switch-active {
    background-color: #fff;
    border-color: #44444480;
    border-width: 2px;
    border-style: solid;
}
.e-switch-wrapper .e-switch-on {
    background-color: #fff !important;
}
.e-switch-wrapper .e-switch-off {
    background-color: #fff !important;
}
.e-switch-wrapper .e-switch-inner,
.e-css.e-switch-wrapper .e-switch-inner {
    border: 2px solid #44444480;
}
.e-switch-wrapper .e-switch-handle {
    background-color: #ffff;
    box-shadow: none;
    border: 2px solid #44444480;
    margin-left: 6px;
}
