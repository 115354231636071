@mixin scrollbars($size, $thumb-radius, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }
    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $thumb-radius;
    }
    &::-webkit-scrollbar-track {
        background: $background-color;
    }
    // For Internet Explorer
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

// FONT WEIGHTS HELPER CSS
@for $i from 100 through 900 {
    .font-weight-#{$i} {
        font-weight: #{$i} !important;
    }
}

// FONT SIZES HELPER CSS
@for $i from 8 through 50 {
    .font-size-#{$i} {
        font-size: #{$i}px !important;
    }
}

// PADDINGS HELPER CSS
// Padding from 1px to 100px. For example, use p-10 for padding 10px
@for $i from 1 through 100 {
    .p-#{$i} {
        padding: #{$i}px !important;
    }
}

// Padding horizontal from 1px to 100px. For example, use ph-10 for padding 10px
@for $i from 1 through 100 {
    .ph-#{$i} {
        padding-left: #{$i}px !important;
        padding-right: #{$i}px !important;
    }
    .px-#{$i} {
        padding-left: #{$i}px !important;
        padding-right: #{$i}px !important;
    }
}

// Padding vertical from 1px to 100px. For example, use pv-10 for padding 10px
@for $i from 1 through 100 {
    .pv-#{$i} {
        padding-top: #{$i}px !important;
        padding-bottom: #{$i}px !important;
    }
    .py-#{$i} {
        padding-top: #{$i}px !important;
        padding-bottom: #{$i}px !important;
    }
}

// Padding top from 1px to 100px. For example, use pt-10 for padding 10px
@for $i from 1 through 200 {
    .pt-#{$i} {
        padding-top: #{$i}px !important;
    }
}

// Padding bottom from 1px to 100px. For example, use pb-10 for padding 10px
@for $i from 1 through 100 {
    .pb-#{$i} {
        padding-bottom: #{$i}px !important;
    }
}

// Padding left from 1px to 100px. For example, use pl-10 for padding 10px
@for $i from 1 through 100 {
    .pl-#{$i} {
        padding-left: #{$i}px !important;
    }
}

// Padding right from 1px to 100px. For example, use pr-10 for padding 10px
@for $i from 1 through 100 {
    .pr-#{$i} {
        padding-right: #{$i}px !important;
    }
}

// MARGINS HELPER CSS
// Margin from 1px to 100px. For example, use m-10 for margin 10px
@for $i from 1 through 100 {
    .m-#{$i} {
        margin: #{$i}px !important;
    }
}

// Margin horizontal from 1px to 100px. For example, use mh-10 for padding 10px
@for $i from 1 through 100 {
    .mh-#{$i} {
        margin-left: #{$i}px !important;
        margin-right: #{$i}px !important;
    }
    .mx-#{$i} {
        margin-left: #{$i}px !important;
        margin-right: #{$i}px !important;
    }
}

// margin vertical from 1px to 100px. For example, use mv-10 for padding 10px
@for $i from 1 through 100 {
    .mv-#{$i} {
        margin-top: #{$i}px !important;
        margin-bottom: #{$i}px !important;
    }
    .my-#{$i} {
        margin-top: #{$i}px !important;
        margin-bottom: #{$i}px !important;
    }
}

// margin top from 1px to 100px. For example, use mt-10 for padding 10px
@for $i from 1 through 100 {
    .mt-#{$i} {
        margin-top: #{$i}px !important;
    }
}

// margin bottom from 1px to 100px. For example, use mb-10 for padding 10px
@for $i from 1 through 100 {
    .mb-#{$i} {
        margin-bottom: #{$i}px !important;
    }
}

// margin left from 1px to 100px. For example, use ml-10 for padding 10px
@for $i from 1 through 100 {
    .ml-#{$i} {
        margin-left: #{$i}px !important;
    }
}

// margin right from 1px to 100px. For example, use mr-10 for padding 10px
@for $i from 1 through 100 {
    .mr-#{$i} {
        margin-right: #{$i}px !important;
    }
}

// 100vh height
@for $i from 1 through 100 {
    .h-#{$i}-vh {
        height: #{$i}vh !important;
    }
}

//100vw width
@for $i from 1 through 100 {
    .w-#{$i}-vw {
        width: #{$i}vw !important;
    }
}

//100% width
@for $i from 1 through 100 {
    $width: percentage($i/100);
    .w-#{$i}-p {
        width: $width !important;
    }
}

//100% Max width
@for $i from 1 through 100 {
    $mx-width: percentage($i/100);
    .mx-w-#{$i}-p {
        max-width: $mx-width !important;
    }
}

//100% Min width
@for $i from 1 through 100 {
    $mn-width: percentage($i/100);
    .mn-w-#{$i}-p {
        min-width: $mn-width !important;
    }
}

//100% Height
@for $i from 1 through 100 {
    $hight: percentage($i/100);
    .h-#{$i}-p {
        height: $hight !important;
    }
}

//100% Min width
@for $i from 1 through 100 {
    $mn-hight: percentage($i/100);
    .mn-h-#{$i}-p {
        min-height: $mn-hight !important;
    }
}

//100% Min width
@for $i from 1 through 100 {
    $mx-hight: percentage($i/100);
    .mx-h-#{$i}-p {
        max-height: $mx-hight !important;
    }
}

@for $i from 1 through 900 {
    .mn-h-#{$i} {
        min-height: #{$i}px !important;
    }
}

.h-100-p {
    height: 100% !important;
}
.h-86-p {
    height: 86% !important;
}
.h-99-p {
    height: 99% !important;
}
.h-88-p {
    height: 88% !important;
}
.h-50-vh {
    height: 50vh !important;
}
.error-text {
    font-size: 13px;
    color: #e5583d;
    margin: 2px 0px 10px 0px;
    font-family: "Open-Sans-Regular";
}

.star {
    font-size: 15px;
    color: #e5583d;
    font-family: "Open-Sans-Regular";
}

.d-flex {
    display: flex;
}

.primary-btn {
    border-radius: 20px;
    padding: 0px 20px;
    box-shadow: none;
    background-color: var(--primary-color); //rebranding changes
    color: #fff;
    height: 36px;
    line-height: 0;
    font-size: 14px;
    font-family: "Montserrat-Bold";
    border: none;
    cursor: pointer;
}

.secondary-btn {
    border-radius: 20px;
    padding: 0px 20px;
    box-shadow: none;
    background-color: #fff;
    color: #444;
    height: 36px;
    line-height: 0;
    font-size: 14px;
    font-family: "Montserrat-Bold";
    border: 1px solid var(--secondary-color); //rebranding changes
    cursor: pointer;
}

.cursor {
    cursor: pointer !important;
}

.margin-auto {
    margin: auto;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.font-color {
    color: #444444 !important;
}

.font-color-primary {
    color: var(--primary-color) !important; //rebranding changes
}

.border-gray {
    border: 1px solid #d7d7d7 !important;
}

.font-color-3131 {
    color: #313131 !important;
}

.font-color-6262 {
    color: #626262 !important;
}

.font-color-4444 {
    color: #444444 !important;
}

.font-colour-555555 {
    color: #555555;
}
.font-color-838383 {
    color: #838383;
}
.font-color-red {
    color: #ff0000;
}
.font-color-success {
    color: #38b000;
}
.text-underline {
    text-decoration: underline;
}
