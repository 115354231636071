// // Typography
// // Fonts

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../assets/fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("../assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Open-Sans-Light";
  src: url("../assets/fonts/opensans/OpenSans-Light.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open-Sans-Regular";
  src: url("../assets/fonts/opensans/OpenSans-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open-Sans-Medium";
  src: url("../assets/fonts/opensans/OpenSans-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open-Sans-SemiBold";
  src: url("../assets/fonts/opensans/OpenSans-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open-Sans-Bold";
  src: url("../assets/fonts/opensans/OpenSans-Bold.ttf") format("truetype");
  font-display: swap;
}

// // Sizes
.montserrat-regular{
  font-family: 'Montserrat-Regular';
}

.montserrat-medium{
  font-family: 'Montserrat-Medium';
}
.montserrat-semibold{
  font-family: 'Montserrat-SemiBold';
}
.montserrat-Bold{
  font-family: 'Montserrat-Bold';
}
.open-sans-light{
  font-family: 'Open-Sans-Light';
}
.open-sans-regular{
  font-family: 'Open-Sans-Regular';
}
.open-sans-medium{
  font-family: 'Open-Sans-Medium';
}
.open-sans-semibold{
  font-family: 'Open-Sans-SemiBold';
}
.open-sans-bold{
  font-family: 'Open-Sans-Bold';
}
.montserrat-Bold-italic{
  font-family: 'Montserrat-Bold';
  font-style: italic;
}
.montserrat-italic{
  font-family: 'Montserrat-Medium';
  font-style: italic;
}

// Sizes
$base-font-size: 12px;
$base-line-height: 1;
